<template>
  <div>
    <PageTitle :value="title" />
    <div v-if="isDataFetched">
      <div>
        <div>
          <Uploader
              :previous-image="userData.thumbnail"
              @set-image-id="handleSetImageId"
          />
        </div>
      </div>
      <div
          v-for="(value, name) in inputData"
          :key="name"
      >
        <Input
            v-if="inputData[name].type === 'text'"
            v-model="userData[name]"
            :label="name"
            :is-disabled="inputData[name].isDisabled"
        />
        <Checkbox
            v-else-if="inputData[name].type === 'checkbox'"
            v-model="userData[name]"
            :label="name"
            :is-disabled="inputData[name].isDisabled"
        />
        <Select
            v-else-if="inputData[name].type === 'select'"
            v-model="userData[name]"
            :label="name"
            :items="inputData[name].options"
            :item-value="inputData[name].itemValue"
        />
      </div>
    </div>
    <div class="footer">
      <div class="delete_btn_container">
        <v-btn
            color="secondary"
            class="mr-4"
            @click="handleOpenModal('close')"
        >
          {{ $t("detail.CLOSE") }}
        </v-btn>
        <DeleteButton @click="handleOpenModal('delete')" />
        <SaveButton @click="handleSaveGameSet" />
      </div>
    </div>
    <Modal
        v-if="isModalShown"
        :title="$t('modal.WARNING')"
        :desc="modalType === 'delete' ? $t('modal.DELETE_GAMESET_MESSAGE') : $t('modal.CLOSE_GAMESET_MESSAGE')"
        @yes="$_detail_handleProceed(modalType === 'delete' ? $_detail_handleDelete : handleCloseGameset)"
        @no="$_detail_handleClose"
    />
  </div>
</template>

<script>
import PageTitle from "@/components/common/headers/PageTitle"
import Uploader from "@/components/inputs/image/Uploader"
import Input from "@/components/common/formControls/Input"
import Checkbox from "@/components/common/formControls/Checkbox"
import Select from "@/components/common/formControls/Select"
import SaveButton from "@/components/common/buttons/SaveButton"
import DeleteButton from "@/components/common/buttons/DeleteButton"
import Modal from "@/components/common/modals/Modal"
import detail from "@/mixins/detail"
import options from "@/mixins/options"
import { closeGamesetById, lookupByGameSetId, } from "@/api/lookup"

export default {
  components: {
    PageTitle,
    Input,
    Uploader,
    Checkbox,
    Select,
    SaveButton,
    DeleteButton,
    Modal,
  },
  mixins: [detail, options],
  data() {
    return {
      title: this.$t("nav.GAME_PDF"),
      userData: {
        id: "",
        user: "",
        template: "",
        thumbnail: null,
        name: "",
        max_entry: null,
        valid_from: "",
        valid_until: "",
        description: "",
        divisions: "",
        survey: null,
        code: "",
        is_public: null,
        for_study: null,
        for_mobile: null,
        show_selfscore: null,
        score_person: null,
        score_company: null,
        code_required: null,
        tel_required: null,
        email_required: null,
        is_started: null,
        is_completed: null,
        is_deleted: null,
        created_at: "",
      },
      inputData: {
        id: {
          type: "text",
          isDisabled: true,
        },
        user: {
          type: "text",
          isDisabled: true,
        },
        template: {
          type: "select",
          options: [],
        },
        name: {
          type: "text",
        },
        max_entry: {
          type: "text",
        },
        valid_from: {
          type: "text",
          placeholder: this.$t("gamesets.DATE_PLACEHOLDER"),
        },
        valid_until: {
          type: "text",
          placeholder: this.$t("gamesets.DATE_PLACEHOLDER"),
        },
        description: {
          type: "text",
        },
        divisions: {
          type: "text",
        },
        survey: {
          type: "select",
          options: [],
        },
        code: {
          type: "text",
          isDisabled: true,
        },
        is_public: {
          type: "checkbox",
        },
        for_study: {
          type: "checkbox",
        },
        for_mobile: {
          type: "checkbox",
          isDisabled: true,
        },
        show_selfscore: {
          type: "checkbox",
        },
        score_person: {
          type: "select",
          options: [],
        },
        score_company: {
          type: "select",
          options: [],
        },
        code_required: {
          type: "checkbox",
        },
        tel_required: {
          type: "checkbox",
        },
        email_required: {
          type: "checkbox",
        },
        is_started: {
          type: "checkbox",
        },
        is_completed: {
          type: "checkbox",
        },
        is_deleted: {
          type: "checkbox",
        },
        created_at: {
          type: "text",
          widthClass: "width_70",
        },
      },
      modalType: null,
    }
  },
  created() {
    const fetchTemplateOption = this.$_options_setFetchedOptions("template")
    const fetchSurveyOption = this.$_options_setFetchedOptions("survey")

    this.fetchGameSetId()
    Promise.all([fetchTemplateOption, fetchSurveyOption]).then(() => {
      this.inputData.template.options = this.items.template.options
      this.inputData.survey.options = this.items.survey.options
    })
  },
  methods: {
    async fetchGameSetId() {
      try {
        const { data, } = await lookupByGameSetId(this.$route.params.id)
        const scores_person = data.data.template.scores_person
        const scores_company = data.data.template.scores_company

        this.inputData.score_person.options = !scores_person ? [] : scores_person
        this.inputData.score_company.options = !scores_company ? [] : scores_company
      } catch (error) {
        console.log(error)
      }
    },
    handleSetImageId(imageId) {
      if (this.userData.thumbnail) {
        this.userData.thumbnail.id = imageId
      } else {
        this.userData.thumbnail = {
          id: imageId,
        }
      }
    },
    handleSaveGameSet() {
      const data = { ...this.userData, }

      if (data.thumbnail && data.thumbnail.id) {
        data.thumbnail = { id: data.thumbnail.id, }
      } else {
        data.thumbnail = null
      }

      if (data.divisions) {
        data.divisions = data.divisions.split(",").map((division) => division.trim())
      } else {
        data.divisions = []
      }

      for (const key in this.inputData) {
        if (this.inputData[key].isDisabled) {
          delete data[key]
        }
      }

      this.$_detail_handleSave(data)
    },
    async handleCloseGameset() {
      try {
        await closeGamesetById(this.id)
        this.$_detail_handleRedirect(this.category)
      } catch (error) {
        this.$store.dispatch("setError", error, { root: true, })
      }
    },
    handleOpenModal(type) {
      this.modalType = type
      this.isModalShown = true
    },
  },
}
</script>

<style scoped>
.footer {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 2rem 0;
}

.save_btn_wrapper {
  position: absolute;
  left: calc(50% - 32px);
}
</style>
